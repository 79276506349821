import styled from "@emotion/styled"
import css from "@styled-system/css"

const Gradient = styled.section`
  background-image: radial-gradient(
    circle at ${props => (props.position ? props.position : "center bottom")},
    ${props => `${props.color[0]} 10%, ${props.color[1]} 60%`}
  );
  ${css({
    py: 6,
  })}
`

export default Gradient
