import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { useTheme } from "emotion-theming"
import styled from "@emotion/styled"
import { rgba } from "polished"
import css from "@styled-system/css"

import { Container } from "@bigif/components/grid"
import { Medium, Heading } from "@bigif/components/typography"
import Gradient from "@bigif/components/gradient"

const CyberAttack = () => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query CyberAttackQuery {
      cyberattack: file(relativePath: { eq: "cyberattack-july.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const GradientWrapper = styled.div`
    background-image: linear-gradient(
      to bottom,
      ${`${rgba(224, 147, 146, 1.0)}, ${rgba(182, 47, 46, 1.0)}`}
    );
    ${css({
      py: 6,
    })}
  `

  return (
    <Gradient color={theme.colors.primary}>
      {/* <GradientWrapper> */}
      <Container>
        <Heading as="h1" fontSize={4}>
          Cyber-Attack
        </Heading>
        <Medium>
          Without the right insurance, a business may not be able to come back
          from the devastating impact of a cyber attack.
        </Medium>
        <Img fluid={data.cyberattack.childImageSharp.fluid} alt="" />
      </Container>
      {/* </GradientWrapper> */}
    </Gradient>
  )
}

export default CyberAttack
