import styled from "@emotion/styled"
import css from "@styled-system/css"

const House = styled.div`
  ${css({
    bg: "primary.1",
    color: "text",
    p: 5,
    m: 0,
    textAlign: "center",
    position: "relative",
    zIndex: 3,
    "&:after": {
      bg: "primary.1",
      width: "calc(100% + 1px)",
      height: "0",
      clipPath: "polygon(50% 0, 0 100%, 100% 100%)",
      pb: "30%",
      left: 0,
      content: "''",
      display: "block",
      top: "0.5px",
      transform: "translateY(-100%)",
      position: "absolute",
      zIndex: 0,
    },
    "&>p": {
      position: "relative",
      zIndex: 3,
    },
  })};
`
export default House
