import React, { useEffect, useState } from "react"
import css from "@styled-system/css"
import { useTheme } from "emotion-theming"
import VisuallyHidden from "@reach/visually-hidden"

import { Container } from "@bigif/components/grid"
import Card from "@bigif/components/card"
import { Heading } from "@bigif/components/typography"
import Gradient from "@bigif/components/gradient"

const Partners = () => {
  const theme = useTheme()
  const [partners, setPartners] = useState([])

  useEffect(() => {
    fetch("/partners.json")
      .then(res => res.json())
      .then(res => setPartners(res))
      .catch(err => window.console.log(err))
  }, [])

  return (
    <Gradient
      css={css({ py: [6, 7] })}
      color={theme.colors.secondary}
      position="center"
    >
      <Container>
        <Heading as="h1" textAlign="center" fontSize={[4, 5]}>
          Our Insurer Partners
        </Heading>
        <Card variant="white">
          <img
            src="/partners-july.jpg"
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
          {partners.length > 0 && (
            <VisuallyHidden>
              <ul>
                {partners.map(partner => (
                  <li key={partner}>{partner}</li>
                ))}
              </ul>
            </VisuallyHidden>
          )}
        </Card>
      </Container>
    </Gradient>
  )
}

export default Partners
