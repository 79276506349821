import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { useTheme } from "emotion-theming"

import { Container } from "@bigif/components/grid"
import { Medium, Heading } from "@bigif/components/typography"
import Gradient from "@bigif/components/gradient"

const Sunken = () => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query SunkenQuery {
      sunken: file(relativePath: { eq: "sunken.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Gradient color={theme.colors.secondary}>
      <Container>
        <Heading as="h1" fontSize={4}>
          Earthquake
        </Heading>
        <Medium>
        An average of 300 earthquakes occur in Canada every month. Most are not felt, but it only takes one to upend your life. Talk to a broker to see if you are covered where you live.
        </Medium>
        <Img fluid={data.sunken.childImageSharp.fluid} alt="" />
      </Container>
    </Gradient>
  )
}

export default Sunken
