import React from "react"
import { animateScroll } from "react-scroll"
import styled from "@emotion/styled"
import css from "@styled-system/css"
import VisuallyHidden from "@reach/visually-hidden"

import Button from "@bigif/components/button"

const StyledButton = styled(Button)`
  bottom: 0.5rem;
  position: fixed;
  right: 0.5rem;
  width: auto;
  z-index: 9999;
  ${css({
    boxShadow: "card",
    fontSize: 1,
  })}
`

const ToTop = () => {
  return (
    <StyledButton variant="smPrimary" onClick={() => animateScroll.scrollToTop()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="20"
        fill="none"
        viewBox="0 0 32 20"
      >
        <path
          fill="currentColor"
          d="M16.85.363l14.798 15.101c.47.484.47 1.266 0 1.75l-1.98 2.036c-.47.484-1.23.484-1.7 0L16 7.06 4.032 19.25c-.47.484-1.23.484-1.7 0l-1.98-2.037a1.261 1.261 0 010-1.749L15.15.363a1.177 1.177 0 011.7 0z"
        ></path>
      </svg>
      <VisuallyHidden>To Top</VisuallyHidden>
    </StyledButton>
  )
}

export default ToTop
