import React from "react"

import { WaveContainer, WaveBottom, WaveImg } from "./styles"

const Wave = ({ delay, duration }) => {
  return (
    <WaveContainer>
      <WaveImg delay={delay} duration={duration} />
      <WaveBottom />
    </WaveContainer>
  )
}

export default Wave
