import styled from "@emotion/styled"
import css from "@styled-system/css"

import Button from "@bigif/components/button"
import {
  Menu as ReachMenu,
  MenuList as ReachList,
  MenuButton as ReachButton,
} from "@reach/menu-button"

export const Menu = ReachMenu

export const MenuList = styled(ReachList)`
  ${css({
    bg: "text",
    border: "none",
    borderRadius: "button",
    boxShadow: "card",
    fontSize: [0, 2],
    py: 1,
    mt: 1,
  })}
`
export const MenuButton = Button.withComponent(ReachButton)
