import React from "react"

import { Container } from "@bigif/components/grid"
import House from "@bigif/components/house"
import { Large } from "@bigif/components/typography"

import Wave from "./wave"
import { Wrapper } from "./styles"

const Waves = () => {
  return (
    <Wrapper>
      <Container>
        <House>
          <Large>
          The proper insurance can help you rebuild your home or business after a catastrophic event, or a cyber attack.
          </Large>
        </House>
      </Container>
      <Wave delay="1s" duration="3s" />
      <Wave delay="2s" duration="4s" />
      <Wave duration="5s" />
    </Wrapper>
  )
}

export default Waves
