import React from "react"
import { Box } from "reflexbox"
import styled from "@emotion/styled"
import css from "@styled-system/css"

export const Large = styled.p`
  ${css({
    fontSize: [2, 3, 4],
  })}
`

export const Medium = styled.p`
  ${css({
    fontSize: [1, 2],
  })}
`

export const Heading = props => (
  <Box as="h2" mb={4} mt={3} variant="heading" tx="tx" {...props} />
)
