import React from "react"
import css from "@styled-system/css"
import { useTheme } from "emotion-theming"

import { Container, Col, Row } from "@bigif/components/grid"
import Button from "@bigif/components/button"
import Card from "@bigif/components/card"
import { Medium, Heading } from "@bigif/components/typography"
import Gradient from "@bigif/components/gradient"

const Stats = () => {
  const theme = useTheme()
  const handleClick = e => {
    e.preventDefault()
	const { href } = e.currentTarget
    if (typeof window != undefined) {
      if (window.gtag) {
        window.gtag("event", "conversion", {
          send_to: "AW-837012328/oA-KCN6chdoCEOiWj48D",
          event_callback: () => {
            if (href) {
              window.location = href
            }
          },
        })
      }

      return false
    }
  }
  return (
    <Gradient
      css={css({ pt: [6, 7] })}
      color={theme.colors.secondary}
      position="center"
    >
      <Container>
        <Heading as="h1" textAlign="center" fontSize={[4, 5]}>
          Statistics &amp;Resources
        </Heading>
        <Row>
          <Col width={[1, 1 / 2]}>
            <Card variant="secondary" align="center">
              <Heading fontSize={[2, 3]} textAlign="center">
                Intact Centre on Climate Adaptation
              </Heading>
              <Medium>
                The Intact Centre on Climate Adaptation has tons of resources
                for flood preparedness.
              </Medium>
              <Button
                as="a"
                onClick={handleClick}
                href="https://www.intactcentre.ca/programs/home_flood_protect/"
                variant="secondary"
              >
                See Resources
              </Button>
            </Card>
          </Col>
          <Col width={[1, 1 / 2]}>
            <Card variant="secondary" align="center">
              <Heading fontSize={[2, 3]} textAlign="center">
                Earthquake Risk Mapping
              </Heading>
              <Medium>
                The ICLR offer an earthquake risk mapping tool and more.
              </Medium>
              <Button
                as="a"
                onClick={handleClick}
                href="https://www.iclr.org/earthquake-risk/"
                variant="secondary"
              >
                Use the Tool
              </Button>
            </Card>
          </Col>
          <Col width={[1, 1 / 2]}>
            <Card variant="secondary" align="center">
              <Heading fontSize={[2, 3]} textAlign="center">
                Flooding in Canada
              </Heading>
              <Medium>
                Read more about flooding in Canada, from Partners for Action.
              </Medium>
              <Button
                as="a"
                onClick={handleClick}
                href="https://uwaterloo.ca/partners-for-action/"
                variant="secondary"
              >
                Flooding in Canada
              </Button>
            </Card>
          </Col>
          <Col width={[1, 1 / 2]}>
            <Card variant="secondary" align="center">
              <Heading fontSize={[2, 3]} textAlign="center">
                Earthquakes in Canada
              </Heading>
              <Medium>
                Natural Resources Canada keeps a detailed record of earthquakes
                in Canada.
              </Medium>
              <Button
                as="a"
                onClick={handleClick}
                href="http://earthquakescanada.nrcan.gc.ca/index-en.php"
                variant="secondary"
              >
                Earthquakes in Canada
              </Button>
            </Card>
          </Col>
          <Col width={[1, 1 / 2]}>
            <Card variant="secondary" align="center">
              <Heading fontSize={[2, 3]} textAlign="center">
                Emergency Kits
              </Heading>
              <Medium>
                The Canadian Red Cross has your emergency kit needs covered.
              </Medium>
              <Button
                as="a"
                onClick={handleClick}
                href="https://www.redcross.ca/how-we-help/emergencies-and-disasters-in-canada/be-ready-emergency-preparedness-and-recovery/get-an-emergency-kit"
                variant="secondary"
              >
                Get an Emergency Kit
              </Button>
            </Card>
          </Col>
          <Col width={[1, 1 / 2]}>
            <Card variant="secondary" align="center">
              <Heading fontSize={[2, 3]} textAlign="center">
                Flood Causes, Costs and Occurrences
              </Heading>
              <Medium>
                The Government of Canada has compiled a resource on flood
                causes, costs, and occurrences.
              </Medium>
              <Button
                as="a"
                onClick={handleClick}
                href="http://earthquakescanada.nrcan.gc.ca/index-en.php"
                variant="secondary"
              >
                Flood Causes
              </Button>
            </Card>
          </Col>
        </Row>
        <Medium
          css={css({
            textAlign: "center",
            mt: 6,
          })}
        >
          Insurance Brokers Association of Canada
          <br />
          Exchange Tower
          <br />
          130 King Street West, Suite 2125, PO Box 443
          <br />
          Toronto, Ontario  M5X 1E4
        </Medium>
        <Medium
          css={css({
            textAlign: "center",
            mt: 6,
          })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="82"
            fill="none"
            viewBox="0 0 65 82"
            css={css({
              display: "block",
              mx: "auto",
              mb: 4,
            })}
          >
            <path
              fill="#F8F9FA"
              d="M64.296 40.97l-2.074 25.193c-.531 6.518-10.274 5.621-9.744-.785l1.608-19.62-6.608.368a24.851 24.851 0 012.54 10.971c0 6.599-2.588 12.605-6.8 17.057l-6.32-6.294c9.31-10.202 2.107-26.779-11.897-26.779a16.051 16.051 0 00-10.804 4.164l-6.319-6.294a24.976 24.976 0 0113.088-6.438l12.106-13.726-6.849-3.972-8.296 7.367c-4.824 4.293-11.351-2.963-6.512-7.271l10.933-9.722a4.889 4.889 0 015.708-.576S50.453 17.57 50.485 17.602c2.604 1.617 3.328 5.765.98 8.424l-9.389 10.65 17.059-.944c2.974-.176 5.402 2.306 5.16 5.237zM53.86 16.303c4.518 0 8.184-3.651 8.184-8.152 0-4.5-3.666-8.152-8.184-8.152s-8.184 3.652-8.184 8.152c0 4.5 3.666 8.152 8.184 8.152zm-28.844 56.81c-12.959 0-20.483-14.511-13.296-25.002l-6.383-6.358A24.579 24.579 0 000 57.081c0 20.933 24.23 32.257 40.42 19.62l-6.383-6.358a16.008 16.008 0 01-9.02 2.77z"
            ></path>
          </svg>
          WCAG 2.1 Level AA Compliant
          <br />
          &copy;2019, Insurance Brokers of Canada
        </Medium>
      </Container>
    </Gradient>
  )
}

export default Stats
