import React from "react"

import { Text, Wrapper, CrackLeft, CrackRight, Inner } from "./styles"

const Cracks = () => {
  return (
    <Wrapper>
      <CrackLeft />
      <Inner>
        <Text>
        Many Canadians aren’t properly covered in the event of overland water or earthquake damage. Most businesses aren’t covered in the event of a cyber attack.
        </Text>
      </Inner>
      <CrackRight />
    </Wrapper>
  )
}

export default Cracks
