import React from "react"
import styled from "@emotion/styled"
import { rgba } from "polished"
import { useMediaLayout } from "use-media"

import srcLg from "@bigif/assets/videos/logo-lg.mp4"
import srcSm from "@bigif/assets/videos/logo-sm.mp4"

const VideoWrapper = styled.div`
  position: relative;
  &:after {
    background-image: linear-gradient(
      to top,
      ${props =>
        `${rgba(props.theme.colors.primary[1], 1.0)}, ${rgba(
          props.theme.colors.primary[1],
          0.0
        )}`}
    );
    bottom: 0;
    display: block;
    content: "";
    height: 30%;
    position: absolute;
    width: 100%;
    z-index: 3;
  }

  video {
    height: auto;
    width: 100%;
  }
`

const LogoAnim = () => {
  const isWide = useMediaLayout({ minWidth: 1000 })
  const videoSrc = isWide ? srcLg : srcSm

  return (
    <VideoWrapper>
      <video autoPlay muted key={videoSrc}>
        <source src={videoSrc} type="video/mp4" />
      </video>
    </VideoWrapper>
  )
}

export default LogoAnim
