import styled from "@emotion/styled"
import css from "@styled-system/css"

import { Container } from "@bigif/components/grid"
import { Large } from "@bigif/components/typography"
import CrackOne from "@bigif/svgs/crack-one"
import CrackTwo from "@bigif/svgs/crack-two"

export const Text = styled(Large)`
  text-align: center;
`

const Crack = styled.svg`
  height: auto;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 50%;
  z-index: 1;
`

const StyledCrackOne = Crack.withComponent(CrackOne)
const StyledCrackTwo = Crack.withComponent(CrackTwo)

export const CrackLeft = styled(StyledCrackOne)`
  left: 0;
  transform: translate(-50%);
`

export const CrackRight = styled(StyledCrackTwo)`
  right: 0;
  transform: translateX(35%);
`

export const Inner = styled(Container)`
  position: relative;
  z-index: 3;
`

export const Wrapper = styled.section`
  ${css({
    py: [5, 7],
    overflow: "hidden",
    position: "relative",
    zIndex: 3,
  })}
`
