import styled from "@emotion/styled"
import css from "@styled-system/css"

import waveImg from "@bigif/assets/images/wave.png"

const waveSize = "150px"

export const WaveImg = styled.div`
  @keyframes move_wave {
    0% {
      transform: translateX(0) translateZ(0) scaleY(1);
    }
    50% {
      transform: translateX(-25%) translateZ(0) scaleY(0.55);
    }
    100% {
      transform: translateX(-50%) translateZ(0) scaleY(1);
    }
  }

  background-image: url(${waveImg});
  background-repeat: repeat no-repeat;
  background-size: 50% ${waveSize};
  background-position: 0 bottom;
  transform-origin: center bottom;
  left: 0;
  height: ${waveSize};
  position: absolute;
  width: 200%;
  top: 0;
  animation: move_wave ${props => (props.duration ? props.duration : "7s")}
    linear infinite;
  animation-delay: ${props => (props.delay ? props.delay : "0s")};
`

export const WaveContainer = styled.div`
  bottom: 0;
  overflow: hidden;
  padding-top: ${waveSize};
  position: absolute;
  width: 100%;
  z-index: -1;
`

export const WaveBottom = styled.div`
  @keyframes moveOceanLg {
    0% {
      height: 0px;
    }
    100% {
      height: 700px;
    }
  }

  @keyframes moveOceanMd {
    0% {
      height: 0px;
    }
    100% {
      height: 500px;
    }
  }

  @keyframes moveOceanSm {
    0% {
      height: 0px;
    }
    100% {
      height: 400px;
    }
  }

  animation: moveOceanSm 8s alternate infinite;

  ${props => props.theme.mediaQueries.medium} {
    animation: moveOceanMd 8s alternate infinite;
  }

  ${props => props.theme.mediaQueries.large} {
    animation: moveOceanLg 8s alternate infinite;
  }

  background-image: linear-gradient(
    to bottom,
    ${props =>
      `${props.theme.colors.secondary[1]}, ${props.theme.colors.secondary[2]}`}
  );
`

export const Wrapper = styled.section`
  ${css({
    pt: 7,
    position: "relative",
  })}
`
