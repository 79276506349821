import styled from "@emotion/styled"
import css from "@styled-system/css"
import { variant } from "styled-system"

const Card = styled.div`
  ${props => (props.align ? `text-align: ${props.align}` : "")};
  ${css({
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    boxShadow: "card",
    borderRadius: "default",
    boxSizing: "border-box",
    height: "100%",
    p: 4,
    "&>a": {
      mt: "auto",
    },
  })}
  ${variant({
    variants: {
      primary: {
        bg: "primary.2",
      },
      secondary: {
        bg: "secondary.2",
      },
      white: {
        bg: "text",
        color: "secondary.2",
      },
    },
  })}
`

export default Card
